<template>
  <v-card
    :class="`${bInfoOnly ? 'pa-0' : 'px-4 py-6'} fill-height`"
    rounded="lg"
    :elevation="bInfoOnly ? 0 : 3"
  >
    <div class="d-flex flex-column justify-space-between fill-height" :style="m_iRowStyle">
      <div>
        <v-row no-gutters :style="m_iRowStyle">
          <v-col cols="12">
            <v-row
              no-gutters
              justify="space-between"
              :align="g_bLowerBr ? 'center' : 'start'"
              :style="m_iRowStyle"
            >
              <v-col :cols="bInfoOnly ? 12 : 10">
                <h1
                  :class="`kda-ts-${
                    g_bLowerBr ? (bInfoOnly ? '24' : '16') : '28'
                  }pt wt-extrabold line-clamp-2`"
                >
                  {{ m_objVacancyTitle.strText }}
                </h1>
              </v-col>

              <v-col v-if="!bInfoOnly" cols="1" class="d-flex justify-end">
                <v-menu offset-y left open-on-hover>
                  <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon class="main-bw-400--text"> mdi-dots-horizontal </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="pa-0">
                    <v-list-item class="pa-0">
                      <v-btn text block class="text-capitalize kda-ts-16pt nunito wt-semibold">
                        {{ m_strMenu }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="strLabel" cols="12">
            <v-chip
              :color="strLabelColor"
              :style="{ backgroundColor: `${hexLabelColor} !important` }"
              class="kda-ts-16pt nunito wt-semibold white--text"
            >
              {{ strLabel }}
            </v-chip>
          </v-col>

          <v-col cols="12">
            <p
              :class="`${g_bLowerBr ? 'kda-ts-12pt' : 'kda-ts-22pt'} nunito ln-170 ${
                !bInfoOnly && (g_bLowerBr ? 'line-clamp-3' : 'line-clamp-4')
              }`"
            >
              {{ strVacancyDescription }}
            </p>
          </v-col>
        </v-row>
      </div>

      <div>
        <v-row no-gutters :style="m_iRowStyle">
          <v-col cols="12">
            <component
              :is="m_objDateInfo.strComponent"
              :class="`kda-ts-${g_bLowerBr ? '12' : '18'}pt nunito wt-bold`"
            >
              {{ m_objDateInfo.strText }}
            </component>
          </v-col>

          <v-col v-if="!bInfoOnly" cols="12">
            <v-btn
              color="main-r-450"
              block
              class="white--text text-capitalize kda-ts-16pt nunito wt-bold"
              @click="$emit('apply')"
            >
              {{ m_strButtonLabel }}
              <v-icon right> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import * as moment from 'moment';

export default {
  name: 'KDACareerCard',

  mixins: [BaseBreakpoint],

  props: {
    strVacancyTitle: {
      type: String,
      default: ''
    },
    strLabel: {
      type: String,
      default: ''
    },
    hexLabelColor: {
      type: String,
      default: ''
    },
    strLabelColor: {
      type: String,
      default: ''
    },
    strVacancyDescription: {
      type: String,
      default: ''
    },
    tDatePosted: {
      type: Date,
      default: () => new Date()
    },
    tDateClosing: {
      type: Date,
      default: () => new Date()
    },
    bInfoOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    m_objVacancyTitle() {
      return {
        strText: this.strVacancyTitle,
        strComponent: 'p'
      };
    },
    m_strButtonLabel() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.opening.apply');
    },
    m_strMenu() {
      return this.$vuetify.lang.t('$vuetify.kda.careers.opening.share');
    },
    m_objDateInfo() {
      return {
        strText: `${this.$vuetify.lang.t('$vuetify.kda.careers.details.before')} ${moment(
          this.tDateClosing
        ).format('DD MMMM YYYY')}`,
        strComponent: 'h5'
      };
    },
    tDatePostedFromNow() {
      const diffInTime = new Date().getTime() - this.tDatePosted.getTime();
      const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
      const diffInMonth = Math.floor(diffInDays / 30);
      const diffInYear = Math.floor(diffInDays / 365);

      switch (true) {
        case diffInDays <= 0:
          return 'just now';
        case diffInDays <= 30:
          if (diffInDays === 1) {
            return `${diffInDays} day ago`;
          } else {
            return `${diffInDays} days ago`;
          }
        case diffInDays <= 365:
          if (diffInMonth === 1) {
            return `${diffInMonth} month ago`;
          } else {
            return `${diffInMonth} months ago`;
          }
        default:
          if (diffInYear === 1) {
            return `${diffInYear} year ago`;
          } else {
            return `${diffInYear} years ago`;
          }
      }
    },
    m_iRowStyle() {
      return { gap: this.g_bLowerBr ? '8px' : '16px' };
    }
  }
};
</script>
